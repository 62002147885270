var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Transparent", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("You can create transparent chip using "),
        _c("code", [_vm._v("transparent")]),
        _vm._v(" prop."),
      ]),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c("vs-chip", { attrs: { transparent: "", color: "primary" } }, [
            _vm._v("\n        Basic Chip\n      "),
          ]),
          _c(
            "vs-chip",
            { attrs: { transparent: "", color: "success" } },
            [
              _c("vs-avatar", { attrs: { text: "LD" } }),
              _vm._v("\n        Avatar Text\n      "),
            ],
            1
          ),
          _c(
            "vs-chip",
            { attrs: { transparent: "", color: "danger" } },
            [_c("vs-avatar"), _vm._v("\n        Avatar Icon\n      ")],
            1
          ),
          _c(
            "vs-chip",
            { attrs: { transparent: "", color: "warning" } },
            [
              _c("vs-avatar", {
                attrs: { src: "https://randomuser.me/api/portraits/men/4.jpg" },
              }),
              _vm._v("\n        Avatar Image\n      "),
            ],
            1
          ),
          _c(
            "vs-chip",
            { attrs: { transparent: "", closable: "", color: "dark" } },
            [_vm._v("\n        Closable chip\n      ")]
          ),
          _c(
            "vs-chip",
            {
              attrs: {
                transparent: "",
                closable: "",
                color: "#24c1a0",
                "close-icon": "close",
              },
            },
            [
              _c("vs-avatar", {
                attrs: {
                  src: "https://randomuser.me/api/portraits/men/16.jpg",
                },
              }),
              _vm._v("\n        Closable chip\n      "),
            ],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <div class="demo-alignment">\n    <vs-chip transparent color="primary">\n      Basic Chip\n    </vs-chip>\n    <vs-chip transparent color="success">\n      <vs-avatar text="LD"/>\n      Avatar Text\n    </vs-chip>\n    <vs-chip transparent color="danger">\n      <vs-avatar />\n      Avatar Icon\n    </vs-chip>\n    <vs-chip transparent color="warning">\n      <vs-avatar src="https://randomuser.me/api/portraits/men/4.jpg"/>\n      Avatar Image\n    </vs-chip >\n    <vs-chip transparent closable color="dark">\n      Closable chip\n    </vs-chip>\n    <vs-chip transparent closable color="#24c1a0" close-icon="close">\n      <vs-avatar src="https://randomuser.me/api/portraits/men/16.jpg"/>\n      Closable chip\n    </vs-chip>\n  </div>\n</template>\n    '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }