var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Add and Remove Items", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("You can add and remove chips with the "),
        _c("code", [_vm._v("vs-chips")]),
        _vm._v(" component. For the main parameter, pass the "),
        _c("code", [_vm._v("items")]),
        _vm._v(" property, which is an array representing each chip"),
      ]),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c("div", { staticClass: "op-block mb-5" }, [
            _vm._v(" " + _vm._s(_vm.chips) + " "),
          ]),
          _c(
            "vs-chips",
            {
              attrs: { color: "rgb(145, 32, 159)", placeholder: "New Element" },
              model: {
                value: _vm.chips,
                callback: function ($$v) {
                  _vm.chips = $$v
                },
                expression: "chips",
              },
            },
            _vm._l(_vm.chips, function (chip, index) {
              return _c(
                "vs-chip",
                {
                  key: `${chip}-${index}`,
                  attrs: { closable: "" },
                  on: {
                    click: function ($event) {
                      return _vm.remove(chip)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(chip) +
                      "\n                "
                  ),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <div class="op-block mb-4"> ' +
            _vm._s("{{ chips }}") +
            ' </div>\n  <vs-chips color="rgb(145, 32, 159)" placeholder="New Element" v-model="chips">\n    <vs-chip\n      :key="chip"\n      @click="remove(chip)"\n      v-for="chip in chips"\n      closable>\n      ' +
            _vm._s("{{ chip }}") +
            "}\n    </vs-chip>\n  </vs-chips>\n</template>\n\n<script>\nexport default {\n  data(){\n    return {\n      chips:[\n        'Dribbble',\n        'GitHub',\n        'Behance',\n        'Vuejs',\n        'Vuexy',\n      ],\n    }\n  },\n  methods: {\n    remove (item) {\n      this.chips.splice(this.chips.indexOf(item), 1)\n    }\n  }\n}\n</script>\n        "
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }