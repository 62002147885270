var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Color", "code-toggler": "" } },
    [
      _c("p", [_vm._v("Change the background color of the chip")]),
      _c(
        "vs-alert",
        {
          staticClass: "my-3",
          attrs: { icon: "warning", active: "true", color: "warning" },
        },
        [
          _c("span", [
            _vm._v("Only "),
            _c("strong", [_vm._v("RGB")]),
            _vm._v(" and "),
            _c("strong", [_vm._v("HEX")]),
            _vm._v(" colors are supported."),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c("vs-chip", { attrs: { color: "primary" } }, [
            _vm._v("\n                Basic Chip\n            "),
          ]),
          _c(
            "vs-chip",
            { attrs: { color: "success" } },
            [
              _c("vs-avatar", { attrs: { text: "LD" } }),
              _vm._v("\n                Avatar Text\n            "),
            ],
            1
          ),
          _c(
            "vs-chip",
            { attrs: { color: "danger" } },
            [
              _c("vs-avatar"),
              _vm._v("\n                Avatar Icon\n            "),
            ],
            1
          ),
          _c(
            "vs-chip",
            { attrs: { color: "warning" } },
            [
              _c("vs-avatar", {
                attrs: { src: "https://randomuser.me/api/portraits/men/4.jpg" },
              }),
              _vm._v("\n                Avatar Image\n            "),
            ],
            1
          ),
          !_vm.isDeleted
            ? _c(
                "vs-chip",
                {
                  attrs: { closable: "", color: "dark" },
                  on: {
                    click: function ($event) {
                      _vm.isDeleted = true
                    },
                  },
                },
                [_vm._v("\n                Closable chip\n            ")]
              )
            : _vm._e(),
          !_vm.isDeleted2
            ? _c(
                "vs-chip",
                {
                  attrs: {
                    closable: "",
                    color: "#24c1a0",
                    "close-icon": "close",
                  },
                  on: {
                    click: function ($event) {
                      _vm.isDeleted2 = true
                    },
                  },
                },
                [
                  _c("vs-avatar", {
                    attrs: {
                      src: "https://randomuser.me/api/portraits/men/16.jpg",
                    },
                  }),
                  _vm._v("\n                Closable chip\n            "),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <div class="demo-alignment">\n\n    <vs-chip color="primary">Basic Chip</vs-chip>\n\n    <vs-chip color="success">\n      <vs-avatar text="LD" />\n      Avatar Text\n    </vs-chip>\n\n    <vs-chip color="danger">\n      <vs-avatar />\n      Avatar Icon\n    </vs-chip>\n\n    <vs-chip color="warning">\n      <vs-avatar src="https://randomuser.me/api/portraits/men/4.jpg" />\n      Avatar Image\n    </vs-chip>\n\n    <vs-chip @click="isDeleted=true" v-if="!isDeleted" closable color="dark">\n      Closable chip\n    </vs-chip>\n\n    <vs-chip @click="isDeleted2=true" v-if="!isDeleted2" closable color="#24c1a0" close-icon="close">\n      <vs-avatar src="https://randomuser.me/api/portraits/men/16.jpg" />\n      Closable chip\n    </vs-chip>\n  </div>\n</template>\n\n<script>\nexport default {\n  data() {\n    return {\n      isDeleted: false,\n      isDeleted2: false,\n    }\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }