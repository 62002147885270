var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Default", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("Helps you represent simple data with colorful options"),
      ]),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c("vs-chip", [_vm._v("Basic Chip")]),
          _c(
            "vs-chip",
            [
              _c("vs-avatar", { attrs: { text: "LD" } }),
              _vm._v("\n                Avatar Text\n            "),
            ],
            1
          ),
          _c(
            "vs-chip",
            [
              _c("vs-avatar"),
              _vm._v("\n                Avatar Icon\n            "),
            ],
            1
          ),
          _c(
            "vs-chip",
            [
              _c("vs-avatar", {
                attrs: { src: "https://randomuser.me/api/portraits/men/4.jpg" },
              }),
              _vm._v("\n                Avatar Image\n            "),
            ],
            1
          ),
          !_vm.isDeleted
            ? _c(
                "vs-chip",
                {
                  attrs: { closable: "" },
                  on: {
                    click: function ($event) {
                      _vm.isDeleted = true
                    },
                  },
                },
                [_vm._v("\n                Closable chip\n            ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <div class="demo-alignment">\n\n    <vs-chip>Basic Chip</vs-chip>\n\n    <vs-chip>\n      <vs-avatar text="LD"/>\n      Avatar Text\n    </vs-chip>\n\n    <vs-chip>\n      <vs-avatar />\n      Avatar Icon\n    </vs-chip>\n\n    <vs-chip>\n      <vs-avatar src="https://randomuser.me/api/portraits/men/4.jpg"/>\n      Avatar Image\n    </vs-chip>\n\n    <vs-chip @click="isDeleted=true" v-if="!isDeleted" closable>\n      Closable chip\n    </vs-chip>\n  </div>\n</template>\n\n<script>\nexport default {\n  data() {\n    return {\n      isDeleted: false,\n    }\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }