var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    {
      attrs: {
        title: "Customize Close and Clear Chips Icons",
        "code-toggler": "",
      },
    },
    [
      _c("p", [
        _vm._v(
          "You can change the icons used for the close button and the clear button when using multiple chips with the "
        ),
        _c("code", [_vm._v("vs-chips")]),
        _vm._v(" component."),
      ]),
      _c("p", [
        _vm._v("For the main parameter, pass the "),
        _c("code", [_vm._v("close-icon")]),
        _vm._v(
          " property, which is the close icon that appears on each chip. You can change the Clear Chips button with the "
        ),
        _c("code", [_vm._v("remove-icon")]),
        _vm._v(" property"),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5",
          attrs: { color: "primary", icon: "new_releases", active: "true" },
        },
        [
          _c("p", [
            _vm._v(
              "Vuesax uses the Google Material Icons font library by default. For a list of all available icons, visit the official "
            ),
            _c(
              "a",
              {
                attrs: { href: "https://material.io/icons/", target: "_blank" },
              },
              [_vm._v("Material Icons page")]
            ),
            _vm._v("."),
          ]),
          _c("p", [
            _vm._v(
              "FontAwesome and other fonts library are supported. Simply use the icon-pack with fa or fas. You still need to include the Font Awesome icons in your project."
            ),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c("div", { staticClass: "op-block mb-5" }, [
            _vm._v(" " + _vm._s(_vm.chips) + " "),
          ]),
          _c(
            "vs-chips",
            {
              attrs: {
                color: "rgb(145, 32, 159)",
                placeholder: "New Element",
                "icon-pack": "feather",
                "remove-icon": "icon-trash-2",
              },
              model: {
                value: _vm.chips,
                callback: function ($$v) {
                  _vm.chips = $$v
                },
                expression: "chips",
              },
            },
            _vm._l(_vm.chips, function (chip, index) {
              return _c(
                "vs-chip",
                {
                  key: `${chip}-${index}`,
                  attrs: {
                    closable: "",
                    "icon-pack": "feather",
                    "close-icon": "icon-trash-2",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.remove(chip)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(chip) +
                      "\n                "
                  ),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <div class="op-block mb-4"> ' +
            _vm._s("{{ chips }}") +
            ' </div>\n  <vs-chips color="rgb(145, 32, 159)" placeholder="New Element" v-model="chips" icon-pack="feather" remove-icon="icon-trash-2">\n    <vs-chip\n      :key="chip"\n      @click="remove(chip)"\n      v-for="chip in chips"\n      closable\n      icon-pack="feather"\n      close-icon="icon-trash-2">\n      ' +
            _vm._s("{{ chip }}") +
            "\n    </vs-chip>\n  </vs-chips>\n</template>\n\n<script>\nexport default {\n  data(){\n    return {\n      chips:[\n        'Dribbble',\n        'GitHub',\n        'Behance',\n        'Vuejs',\n        'Vuexy',\n      ],\n    }\n  },\n  methods: {\n    remove (item) {\n      this.chips.splice(this.chips.indexOf(item), 1)\n    }\n  }\n}\n</script>\n        "
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }