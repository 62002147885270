var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Closable", "code-toggler": "" } },
    [
      _c("p", [_vm._v("For making a chip closable")]),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _vm.chips.length == 0
            ? _c(
                "vs-button",
                { attrs: { type: "filled" }, on: { click: _vm.reset } },
                [_vm._v("Reset Chips")]
              )
            : _vm._e(),
          _vm._l(_vm.chips, function (chip, index) {
            return _c(
              "vs-chip",
              {
                key: index,
                attrs: { closable: "" },
                on: {
                  click: function ($event) {
                    return _vm.remove(chip)
                  },
                },
              },
              [_vm._v(" " + _vm._s(chip) + " ")]
            )
          }),
        ],
        2
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <div class="demo-alignment">\n\n    <vs-button v-if="chips.length == 0" @click="reset" type="filled">Reset Chips</vs-button>\n    <vs-chip @click="remove(chip)" v-for="(chip, index) in chips" :key="index" closable> ' +
            _vm._s("{{ chip }}") +
            "} </vs-chip>\n\n  </div>\n</template>\n\n<script>\nexport default {\n  data(){\n    return {\n      chips:[\n        'Dribbble',\n        'GitHub',\n        'Behance',\n        'Vuejs',\n        'Vuexy',\n      ],\n    }\n  },\n  methods: {\n    reset () {\n      this.chips = [\n        'Dribbble',\n        'GitHub',\n        'Behance',\n        'Vuejs',\n        'Vuexy',\n      ]\n    },\n    remove (item) {\n      this.chips.splice(this.chips.indexOf(item), 1)\n    }\n  }\n}\n</script>\n        "
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }