var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Icon", "code-toggler": "" } },
    [
      _c("p", [_vm._v("Add a nice icon to the chip")]),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c(
            "vs-chip",
            [
              _c("vs-avatar", {
                attrs: { "icon-pack": "feather", icon: "icon-user" },
              }),
              _c("span", [_vm._v("Icon Default")]),
            ],
            1
          ),
          _c(
            "vs-chip",
            { attrs: { color: "primary" } },
            [
              _c("vs-avatar", {
                attrs: { "icon-pack": "feather", icon: "icon-send" },
              }),
              _c("span", [_vm._v("Icon send")]),
            ],
            1
          ),
          _c(
            "vs-chip",
            { attrs: { color: "success" } },
            [
              _c("vs-avatar", {
                attrs: { "icon-pack": "feather", icon: "icon-mail" },
              }),
              _c("span", [_vm._v("Icon markunread")]),
            ],
            1
          ),
          _c(
            "vs-chip",
            { attrs: { color: "danger" } },
            [
              _c("vs-avatar", {
                attrs: { "icon-pack": "feather", icon: "icon-slash" },
              }),
              _c("span", [_vm._v("Icon block")]),
            ],
            1
          ),
          _c(
            "vs-chip",
            { attrs: { color: "warning" } },
            [
              _c("vs-avatar", {
                attrs: { "icon-pack": "feather", icon: "icon-battery" },
              }),
              _c("span", [_vm._v("Icon battery_alert")]),
            ],
            1
          ),
          _c(
            "vs-chip",
            { attrs: { color: "dark" } },
            [
              _c("vs-avatar", {
                attrs: { "icon-pack": "feather", icon: "icon-edit" },
              }),
              _c("span", [_vm._v("Icon edit")]),
            ],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<div class="demo-alignment">\n\n  <vs-chip>\n    <vs-avatar icon-pack="feather" icon="icon-user" />\n    <span>Icon Default</span>\n  </vs-chip>\n\n  <vs-chip color="primary">\n    <vs-avatar icon-pack="feather" icon="icon-send" />\n    <span>Icon send</span>\n  </vs-chip>\n\n  <vs-chip color="success">\n    <vs-avatar icon-pack="feather" icon="icon-mail" />\n    <span>Icon markunread</span>\n  </vs-chip>\n\n  <vs-chip color="danger">\n    <vs-avatar icon-pack="feather" icon="icon-slash" />\n    <span>Icon block</span>\n  </vs-chip>\n\n  <vs-chip color="warning">\n    <vs-avatar icon-pack="feather" icon="icon-battery" />\n    <span>Icon battery_alert</span>\n  </vs-chip>\n\n  <vs-chip color="dark">\n    <vs-avatar icon-pack="feather" icon="icon-edit" />\n    <span>Icon edit</span>\n  </vs-chip>\n</div>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }